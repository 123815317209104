.container{
    background-color: #fff;
    border-radius: 15px;
    padding: 10%;
    margin: 20% 0;
    width: 500px;
    font-weight: bold;
    border-color: black;
    border: 3px solid black;
    box-shadow: 5px 7px 0px black;
}
.container h2{
    text-align: center;
    font-size: x-large;
    margin-bottom: 25px;
}
.container input{
    display: block;
    height: 30px;
    width: 350px;
    border-radius: 5px;
}
.container label{
    font-size: small;
}

.container .bnt{
    width: 80%;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 25px;
}


.AppServices {
  text-align: center;
  h1 {
    color: #7a4397;
    margin: 1em 0 0.5em 0;
  }
  p {
    text-align: start;
    margin: 1em 5em;
  }
}

.service-container {
  display: flex;
  justify-content: space-between;
  margin: 5em;
  height: 15em;
  align-items: center;
  border-color: black;
  border: 3px solid black;
  box-shadow: 5px 7px 0px black;
  background-color: white;
}

.pomodoro-service,
.task-service {
  flex-basis: 45%;
}

.image-container {
  margin: 2em;
  align-items: center;
  img {
    max-width: 100%;
  }
}

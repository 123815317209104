.wrapper-carousel {
  margin: 50px 200px;

  border-style: solid;
}
@media (max-width: 600px) {
  .wrapper-carousel {
    margin: 10px;
  }
}
.carousel {
  border-color: black;
  box-shadow: 10px 10px 0px #000;
  width: 70%;
}

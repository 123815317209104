@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

body,html, #root {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: bold;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #d9d9d9;
}
.App{
  height: 100%;
  width: 100%;
}

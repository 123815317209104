.footer-container {
    background-color: #111;
    color: #fff;
    padding: 50px 0;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 100px;
  }
  
  .footer-title {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .footer-links {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: #ccc;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-links a:hover {
    color: #fff;
  }
  
  .social-links {
    display: flex;
    align-items: center;
  }
  
  .social-icon {
    color: #ccc;
    font-size: 20px;
    margin-right: 20px;
  }
  
  .social-icon:hover {
    color: #fff;
  }
  
  .footer-divider {
    height: 1px;
    background-color: #333;
    margin: 30px 0;
  }
  
  .footer-text {
    font-size: 14px;
    color: #ccc;
    text-align: center;
  }
.contact h1,
p {
  text-align: center;
}

.contact {
  margin: 100px auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 30em;
  background-color: white;
  width: 40em;
  border-color: black;
  border: 3px solid black;
  box-shadow: 5px 7px 0px black;
}

.contact h1 {
  color: #7a4397;
  font-size: 28px;
  font-weight: bold;
  margin: 15px;
}

.contact p {
  margin-bottom: 15px;
}

a {
  color: #7a4397;
}

.ant-form-item-control-input {
  font-size: 16px;
  height: 40px;
  border-radius: 0px;
}

.ant-form-item-control {
  align-items: center;
}

.ant-btn {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;

  background-color: #7a4397;
  border-color: black;
  border-width: 2px;
  box-shadow: 2px 3px 0px black;
}

.ant-form-item-control-input-content > textarea {
  height: 11.5em;
  width: 20em;
  border: 2px solid black;
  box-shadow: 2px 3px 0px black;
}

.ant-form-item-control-input-content {
  height: 50%;
  width: 50%;
}

.ant-form-item-control-input-content > input {
  border: 2px solid black;
  box-shadow: 1px 2px 0px black;
}

.about-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: transparent;
    margin-top: 20px;
    margin-bottom: 22px;
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: none;
    background-color: #fff;
    border-color: black;
    border: 3px solid black;
    box-shadow: 5px 7px 0px black;
}

h1 {
    color: #7a4397;
    font-size: 28px;
    font-weight: bold;
    margin: 15px;
}

.image-grid {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 200px;

    @media (min-width) {
        width: calc(50% - 1rem);
    }
}

.image {
    width: 100%;
    max-width: 150px;
    height: auto;
    border-radius: 50%;
}

.image-description {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
    color: #000000;
    max-width: 200px;
}

.image-email {
    font-style: italic;
    font-size: 12px;
    text-align: center;
    color: #272525;
}